$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-1: #b4b6b8;
$gray-color-2: #292f33;
$error-color: #eb4c3b;
$blue-color: #0090ff;
#salon__form-select {
  .ant-select {
    height: 42px;
  }
  .ant-select-selection-search-input {
    height: 42px;
  }
  .ant-select-selection-item {
    line-height: 1.4;
    font-size: 16px;
  }
  .ant-select-selector {
    height: 42px;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid $gray-color;
    box-shadow: unset !important;
    &:focus-within {
      border-color: $blue-color;
    }
  }
  .ant-select-selection-placeholder {
    color: $gray-color-1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  }
  .ant-form-item-required {
    flex-direction: row-reverse;
    margin-left: -10px !important;
    &::before {
      margin-right: 0;
      margin-left: 5px;
    }
    &::after {
      position: unset !important;
      content: '' !important;
    }
  }

  .ant-form-item-has-error {
    .ant-select-selector {
      border: 1px solid $error-color !important;
    }
  }
  .ant-form-item-label {
    height: 35px !important;
    color: $gray-color-2;
    font-family: $font-primary;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    label {
      font-size: 16px !important;
      display: flex;
      float: left;
    }
  }

  // multiple
  .ant-select-multiple {
    .ant-select-selector {
      padding: 8px 12px;
      .ant-select-selection-overflow {
        height: auto;
        // margin-top: -5px;

        margin-top: -4px;
      }
      .ant-select-selection-search-input {
        height: 22px !important;
      }
      .ant-select-selection-search {
        margin-left: 0 !important;
      }
    }
    .ant-select-selection-item {
      margin-top: 0 !important;
      background-color: #e9f5fd;
      margin-bottom: 0;
    }
    .ant-checkbox-wrapper {
      display: none;
    }
    .ant-select-selection-item-content {
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item-remove {
      svg {
        path {
          fill: #292f33;
        }
      }
    }
  }
}
.salon__form {
  &-select-popup-select {
    .rc-virtual-list-scrollbar-thumb {
      background-color: #00297a !important;
      width: 4px !important;
    }
    .rc-virtual-list-scrollbar {
      display: block !important;
    }
  }
}
