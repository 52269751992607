$semi-gray-color: #e7ecf0;
$white-color: #ffffff;
$gray-color: #d7d9db;
$black-color: #292f33;
.salon {
  &__table-warp-pagination {
    border-top: 1px solid $gray-color;
    margin-top: 8px;
  }
  &__custom-table {
    border-top: 1px solid $gray-color;
    width: 100%;
    &-avatar {
      .ant-table-tbody {
        .ant-table-cell {
          padding: 6px 16px;
          height: 72px;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis !important;
          word-break: keep-all;
        }
      }
    }
    &-pointer {
      .ant-table-tbody {
        .ant-table-cell {
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis !important;
          word-break: keep-all;
        }
      }
    }
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          background: #ffffff;
          height: 36px !important;
          padding: 6px 16px;
          border-bottom: 1px solid $gray-color;
          &::before {
            position: unset !important;
            width: 0 !important;
          }
        }
      }
      .ant-table-body,
      .ant-table-content {
        /* width */
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #dbdddf;
          border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #00297a;
          border-radius: 8px;
        }
      }
      th {
        font-weight: 700 !important;
        color: #292f33 !important;
      }
      .ant-table-tbody {
        .ant-table-row {
          border-radius: 8px !important;
          &:hover {
            border-radius: 8px !important;
            background-color: $semi-gray-color !important;
          }
        }
        .ant-table-cell {
          border-bottom: unset;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis !important;
          word-break: keep-all;
          padding: 11px 16px;
          &:first-child {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
          &:last-child {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
        .ant-table-cell-row-hover {
          background-color: $semi-gray-color !important;
          &:first-child {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
          &:last-child {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
    .more-width {
      &::-webkit-scrollbar {
        width: 10px !important;
      }
    }
    .more-height {
      &::-webkit-scrollbar {
        height: 10px !important;
      }
    }
  }
}
