$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$blue-color: #0090ff;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$semi-white: #e9f5fd;
.salon__form-date-picker {
  .ant-picker-focused {
    border-color: $blue-color !important;
    box-shadow: unset !important;
  }
  .ant-picker {
    height: 42px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $gray-color;
    width: 100%;
    &-input {
      input {
        color: $gray-color-2;
        font-size: 16px;
      }
    }
  }
  .ant-form-item-label {
    width: 100%;
    height: 35px !important;
    label {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      display: flex;
      float: left;
    }
  }
  &-container {
    &-pick-week {
      .ant-picker-week-panel {
        width: 355px;
      }
    }
    .ant-picker-header-view {
      // background-color: #0090ff1a;
      color: $blue-color !important;
      font-family: $font-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      width: 105px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 8px;
      .ant-picker-decade-btn {
        color: $blue-color !important;
        text-align: center;
        font-family: $font-primary;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
      }
      .ant-picker {
        &-month-btn {
          color: $blue-color !important;
          text-align: center;
          font-family: $font-primary;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
        &-year-btn {
          color: $blue-color !important;
          text-align: center;
          font-family: $font-primary;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
    .ant-picker {
      &-date-panel {
        width: 316px !important;
        // height: 316px !important;
      }
      &-content {
        width: 100% !important;
        th {
          width: 40px !important;
          height: 40px !important;
          font-size: 16px;
          font-weight: 600;
          color: $gray-color-2;
        }
      }
      &-cell {
        padding: 0 !important;
        &-inner {
          min-width: 40px !important;
          height: 40px !important;
          line-height: 40px !important;
          text-align: center;
          font-family: $font-primary;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: $gray-color-2;
        }
        &-disabled {
          .ant-picker-cell-inner {
            color: rgba(0, 0, 0, 0.25);
          }
          &::before {
            height: 40px !important;
          }
        }
        &-today {
          .ant-picker-cell-inner {
            width: 40px;
            height: 40px;
            background-color: $semi-white;
            color: $blue-color;
            &::before {
              content: unset !important;
            }
          }
        }
      }
    }
  }
  .ant-picker-disabled {
    svg {
      path {
        fill: $gray-color-3;
      }
    }
  }
}

@media (max-width: 767px) {
  .salon__form-date-picker .ant-picker {
    height: 36px;
  }
}
