.salon__home {
  .slick {
    &-slide {
      height: 100vh;

      img {
        cursor: pointer;
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }
    }

    &-dots {
      position: absolute;
      left: 50%;
      bottom: 19px;
      transform: translateX(-50%);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      list-style-type: none;
      gap: 4px;

      button {
        display: block !important;
        padding: 0 !important;
        width: 12px !important;
        height: 12px !important;
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
        border: none !important;
        border-radius: 50% !important;
        background: #fff;
        opacity: 1 !important;
        &::after {
          display: none;
        }
      }

      li {
        width: auto !important;
        margin-inline: unset;
        &.slick-active {
          width: auto !important;
          button {
            width: 28px !important;
            background: #0090ff;
            border-radius: 20px !important;
          }
        }
      }
    }
  }
}
