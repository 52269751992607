$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$blue-color: #0090ff;
.salon__form-checkbox {
  .ant-checkbox-inner {
    height: 15px !important;
    width: 15px !important;
    border: 2px solid $gray-color-2;
    border-radius: 3px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: transparent;
    }
  }
  .ant-checkbox-inner {
    &::after {
      border-color: #dadada !important;
      left: 2px;
      top: 50%;
      height: 9px !important;
      width: 5.5px !important;
    }
  }
  &-title {
    color: #b4b6b8 !important;
    font-family: $font-primary;
  }

  //checked
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      border: 1px solid $blue-color !important;
    }
    .salon__form-checkbox-title {
      color: $gray-color-2 !important;
    }
  }

  // disabled
  .ant-checkbox-wrapper-disabled {
    span {
      color: $gray-color-2 !important;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        &::after {
          border-color: #dadada !important;
          left: 20%;
          top: 50%;
        }
      }
      .ant-checkbox-inner {
        border: 1px solid #0090ff !important;
        background-color: #0090ff !important;
      }
    }
  }

  .ant-form-item-control-input-content:has(.salon__form-checkbox-tooltip) {
    display: flex;
    .ant-checkbox-wrapper {
      min-width: unset !important;
    }
  }
  &__has-tooltip {
    display: flex;
    align-items: center;
    &__tooltip {
      margin-bottom: 24px;
      > span,
      > span > span {
        display: flex;
        align-items: center;
      }
    }
    .ant-checkbox-wrapper {
      min-width: unset !important;
    }
  }
}
