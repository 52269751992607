.background-color-F6F9FB {
  background-color: #f6f9fb;
}

.background-color-0090FF1A {
  background: #0090ff1a;
}

.custom-btn.background-color-0090FF1A:hover {
  p {
    color: #ffffff;
  }
}

.background-color-EE7E68 {
  background-color: #ee7e68;
}

.background-color-CCF4FF {
  background-color: #ccf4ff;
}

.salon {
  &__checkout {
    &__pay-n-complete-modal {
      .ant-modal-content {
        padding: 0;
        .ant-modal-header,
        .ant-modal-body {
          padding: 20px 40px;
        }
        .ant-modal-header {
          margin: 0;
        }
        .ant-modal-body {
          max-height: 722px !important;
          overflow: auto;
        }
        .ant-modal-footer {
          margin-top: 0;
        }
      }
      &__cash-payment {
        .salon__form-input-number {
          margin: 0;

          .ant-row {
            .ant-col {
              max-width: 100%;
            }
          }
        }
      }
      & .salon__custom-table {
        border: 0;
      }
      &__table {
        & .ant-table {
          background: #f6f9fb;

          & .ant-table-thead,
          & .ant-table-tbody {
            & .ant-table-cell {
              background-color: transparent;
            }
          }

          & .ant-table-thead {
            & .ant-table-cell {
              padding: 8px 20px;
            }
          }
        }
      }
      &__tip-screen {
        .ant-modal-close {
          display: none;
        }
      }
    }
    &__primary-outlined-btn {
      border-color: #0090ff;
      color: #0090ff;
    }
  }
}
