.salon {
  &__form-input {
    &-textarea {
      &.ant-form-item-has-error {
        textarea {
          border: 1px solid #eb4c3b !important;
        }
      }
    }
  }
}
