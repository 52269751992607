$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$white-color: #ffffff;
$blue-color: #0090ff;
$blue-color-2: #2076a8;
$blue-color-3: #043e6a;
$gray-color-2: #a0a2a3;
$text-color: #292f33;
$semi-white-color: #dbdddf;
$background-color: #f4faff;
.salon__qr {
  background: $background-color;

  position: relative;
  height: 100vh;
  &-title-salon {
    font-family: $font-primary;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 78.4px */
    background: linear-gradient(180deg, $blue-color 0%, $blue-color-3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-sub-title-salon {
    width: 490px;
    color: $gray-color;
    text-align: center;
    font-family: $font-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  }
  &-img {
    width: 100%;
    position: absolute;

    &.top {
      top: 0;
      z-index: 1;
    }
    &.bottom {
      bottom: 0;
      z-index: 1;
    }
    img {
      width: 100%;
    }
  }
  &-logout {
    &-content {
      color: $semi-white-color !important;
      font-family: $font-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 27px;
    }
  }
  &-main {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &-container {
      border: 3.91px solid $blue-color-2;
      width: 278.28px;
      height: 277.97px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 278.28px;
        height: 277.97px;
        border: 1px solid $background-color;
        background-color: $background-color;
        transform: rotate(45deg);
      }
    }
    &-qr-scan {
      width: 240.34px !important;
      height: 240.1px !important;
      z-index: 1;
      canvas {
        width: 240.34px !important;
        height: 240.1px !important;
      }
    }
    &__image {
      width: 100%;
      margin-top: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      &-des {
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: center;
        color: $gray-color-2;
        margin-top: 20px;
        z-index: 1;
      }
    }
    &-title {
      color: $text-color;
      line-height: 38.73px;
      font-size: 32px;
      font-weight: 700;
      font-family: $font-primary;
      margin-bottom: 38px;
    }
    &-button {
      background: $blue-color;
      color: $white-color;
      text-decoration: none;
      padding: 12px 30px !important;
      width: 198px;
      height: 58px;
      border-radius: 8px;
      align-items: center;
      line-height: 32px;
      z-index: 10;
      &-text {
        line-height: 33.6px;
        font-size: 24px;
        font-weight: 700;
        font-family: $font-primary;
      }
    }
  }
}
