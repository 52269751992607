.salon_confirm_qr {
  background: #f4faff;
  position: relative;
  height: 100vh;

  &-img {
    width: 100%;
    position: absolute;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }

    img {
      width: 100%;
    }
  }

  &-main {
    z-index: 1;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    min-height: 385px;

    &__image {
      width: 100%;

      &-des {
        margin-top: 40px;
        margin-bottom: 20px;
        font-family: 'Inter';
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0px;
        color: rgba(0, 144, 255, 1);
      }
    }

    &-title {
      font-family: 'Inter';
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0px;
      color: rgba(120, 121, 122, 1);
      margin-bottom: 40px;
    }

    &-button {
      z-index: 999;
      width: 520px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      background: #0090ff;
      color: #fff;
      text-decoration: none;
      padding: 12px 28px 12px 28px;
      height: 64px;
      border-radius: 4px;
      align-items: center;
      line-height: 32px;
      border-radius: 12px;
      display: flex;
      justify-content: center;

      &-text {
        font-family: 'Inter';
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
      }
    }
  }
}
.item-sp {
  display: none;
}
@media (max-width: 767px) {
  .item {
    &-pc {
      display: none;
    }
    &-sp {
      display: block;
    }
  }

  .salon_confirm_qr-main {
    padding-left: 8px;
    padding-right: 8px;
  }

  .salon_confirm_qr-main__image img {
    width: 158px;
  }
  .salon_confirm_qr-main__image-des {
    font-size: 24px;
    line-height: normal;
    margin-top: 24px;
  }
  .salon_confirm_qr-main-title {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 24px;
  }
  .salon_confirm_qr-main-button {
    height: 40px;
    border-radius: 8px;
  }
  .salon_confirm_qr-main-button-text {
    font-size: 16px;
    line-height: normal;
  }
}
