$font-primary: 'Inter', sans-serif;
$black-color: #292f33;
$white-color: #ffffff;
$gray-color: #d7d9db;
.salon__popup-styled {
  // width: 480px !important;

  .ant-modal-title {
    color: $black-color;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .ant-modal-header {
    padding-bottom: 20px;
  }
  &-content {
    color: $black-color;
    text-align: center;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
    &-strong {
      color: $black-color;
      font-family: $font-primary;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
  .ant-modal-content {
    padding: 0;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-modal-header,
    .ant-modal-body {
      padding: 24px 40px 20px 40px;
      margin: 0;
    }
    .ant-modal-footer {
      padding: 0 40px 24px 40px;
      margin: 0;
    }
  }
  .ant-modal-header {
    border-bottom: 1px solid #d7d9db;
  }
}
@media (max-width: 767px) {
  .salon__popup-styled .ant-modal-content .ant-modal-header,
  .salon__popup-styled .ant-modal-content .ant-modal-body {
    padding: 20px;
  }
  .salon__popup-styled .ant-modal-content .ant-modal-footer {
    padding: 0 20px 24px 20px;
  }
  .salon__popup-styled-content {
    font-size: 18px;
    line-height: 150%;
  }
}
