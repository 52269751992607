.salon__appointment-checkin-modal {
  & .ant-modal-content {
    border-radius: 24px;
    padding: 17px 0 45px;
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-close {
      right: 20px !important;
    }
  }

  &-heading {
    border-bottom: 2px solid #0090ff;
    width: 150px;
    text-align: center;
  }

  &-subtitle {
    margin-top: 27px;
  }
}
