$font-primary: 'Inter', sans-serif;
$gray-color-2: #292f33;
$blue-color: #0090ff;

.salon__appointment-setting {
  & .ant-modal-content {
    padding: 0;
  }

  &-heading {
    display: inline-block;
    // padding: 20px 0 0 40px;
    padding: 0;
  }

  & .ant-modal-body {
    overflow-y: auto;
  }

  &-form {
    // padding: 14px 40px 0;
    padding: 0;

    &-group {
      &-content {
        color: #b4b6b8;
        font-family: $font-primary;
      }

      & > .ant-row {
        & > .ant-col {
          max-width: 100%;

          & .ant-checkbox-group {
            flex-direction: column;

            & .ant-checkbox-wrapper {
              margin-bottom: 11px;

              & .ant-checkbox-inner {
                height: 15px !important;
                width: 15px !important;
                border: 2px solid #292f33;
                border-radius: 3px;
              }
            }

            & .ant-checkbox-checked {
              & .ant-checkbox-inner {
                border-color: transparent;
              }
            }

            & .ant-checkbox-inner {
              &::after {
                border-color: #dadada !important;
                left: 20%;
                top: 50%;
              }
            }

            & .ant-checkbox-wrapper-checked {
              & .ant-checkbox-inner {
                border: 1px solid $blue-color !important;
              }

              & .salon__appointment-setting-form-group-content {
                color: $gray-color-2 !important;
              }
            }
          }
        }
      }
    }

    &-select {
      max-width: 100%;
      padding: 0 !important;

      &-order {
        padding-left: 25px !important;
        margin-left: -5px;
      }

      & .ant-col {
        max-width: 100%;
      }
    }

    &-button {
      margin-left: 0 !important;
      margin-right: 0 !important;

      &-common {
        width: 100px;
        height: 38px !important;
        padding: 0 !important;
        border-radius: 8px;
        text-align: center;
        margin-bottom: -4px;
      }
    }
  }
}
