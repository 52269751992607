$font-primary: 'Inter', sans-serif;
$gray-color: #78797a;
$gray-color-1: #b4b6b8;
$gray-color-2: #292f33;
$gray-color-3: #b4b6b8;
$error-color: #eb4c3b;
$blue-color: #0090ff;
.salon__form-input {
  .ant-input {
    height: 42px;
    padding: 10px 12px !important;
    border-radius: 8px;
    border: 1px solid $gray-color;
    color: $gray-color-2;
    font-size: 16px;
    &:active,
    &:hover {
      box-shadow: unset;
    }
    &:focus {
      box-shadow: unset;
      border-color: $blue-color !important;
    }
    &::placeholder {
      color: $gray-color-3 !important;
      font-family: $font-primary;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .ant-form-item-label {
    width: 100%;
    height: 35px !important;
    label {
      color: $gray-color-2;
      font-family: $font-primary;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      display: flex;
      float: left;
    }
  }
  .ant-input-status-error {
    border: 1px solid $error-color !important;
    outline: none !important;
  }
  .ant-form-item-required {
    flex-direction: row-reverse;
    margin-left: -10px !important;
    &::before {
      margin-right: 0;
      margin-left: 5px;
    }
    &::after {
      position: unset !important;
      content: '' !important;
    }
  }
  .ant-input-affix-wrapper {
    gap: 12px;
  }
  .ant-input-affix-wrapper-focused {
    border-color: $blue-color !important;
    box-shadow: unset !important;
  }
  .ant-input-password {
    height: 42px;
    padding: 0 12px 0 0;
    border-radius: 8px;
    border: 1px solid $gray-color;
    &:hover {
      border-color: $gray-color;
    }
    .ant-input {
      height: 100% !important;
      border-radius: 8px;
      color: $gray-color-2;
      font-size: 16px;
      &:focus,
      &:active,
      &:hover {
        box-shadow: unset;
      }
      &::placeholder {
        color: $gray-color-3 !important;
        font-family: $font-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .ant-input.ant-input-borderless {
    border: none;
  }
  &-read-only {
    .ant-input {
      color: $gray-color-3 !important;
      border-color: $gray-color-3 !important;

      &::placeholder {
        color: $gray-color-3 !important;
      }
    }
    .ant-input-password {
      color: $gray-color-3 !important;
      border-color: $gray-color-3 !important;

      &::placeholder {
        color: $gray-color-3 !important;
      }
    }
    .ant-form-item-label {
      color: $gray-color-1 !important;
      label {
        color: $gray-color-1 !important;
      }
    }
  }
}
