.salon__modal-styled {
  &.salon__modal__confirmation_closebatch {
    .ant-modal-content {
      padding: 24px 40px;
      .ant-modal-body {
        padding: 0;
      }
    }
  }
}
