.salon {
  &__checkout {
    &__transactions {
      &.salon__checkout__pay-n-complete-modal {
        .ant-modal-content {
          .ant-modal-body {
            max-height: 608px !important;
          }
        }
      }
    }
  }
  &__transactions {
    &-collapse {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;

      .ant-collapse-item {
        border: none !important;
        .ant-collapse-header {
          padding: 8px 20px !important;
          &:first-child {
            padding-top: 8px !important;
          }
          align-items: center !important;
          border-radius: 8px !important;
          background-color: #f6f9fb;
          justify-content: space-between;

          .ant-collapse-expand-icon {
            margin: 0;
            padding: 0 !important;

            svg {
              path {
                fill: #292f33;
                fill-opacity: 1;
              }
            }
          }
        }
        .ant-collapse-content {
          margin-top: 20px;
          border: none !important;
          padding: 0 20px;

          .ant-collapse-content-box {
            padding: 0;
          }
        }
        .ant-collapse-header-text {
          margin-inline-end: 0 !important;
          flex: 1 1 auto !important;
        }
      }
      &__sale-ticket-icon {
        margin-left: 8px;

        svg {
          path {
            fill: #0090ff;
            fill-opacity: 1;
          }
        }
      }
      &__sale-ticket-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 0 !important;
            background-color: transparent !important;
          }
          .ant-collapse-content {
            padding: 0 !important;
          }
        }
        &__container {
          box-shadow: -1px -1px 20px 0px #0000001a;
          border-radius: 8px;
        }
        &__header {
          border-radius: 8px 8px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &__content {
          &-customer-name {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > div:not(:last-child) {
            border-bottom: 2px solid #c8cacc;
          }
          &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
              flex: 1 1 auto;
              max-width: 50%;
            }
          }
        }
        &__table {
          background-color: #f6f9fb;
          border-radius: 12px;
          > .salon__row-wrap {
            padding: 13px 0;
            border-bottom: 1px solid #d7d9db;
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
      &__ticket {
        &-total {
          width: 167.5px;
          position: relative;
          &__refunded {
            position: absolute;
            font-size: 12px;
            font-weight: 400;
            color: #eb4c3b;
            top: 100%;
            left: -23px;
            line-height: 17px;
          }
        }
      }
      &__detail {
        &__payment {
          > p:last-child {
            border-bottom: 1px solid #d7d9db;
            padding-bottom: 4px;
            width: max-content;
          }
        }
      }
    }
  }
}

.border-bottom-1px-DBDDDF {
  border-bottom: 1px solid #dbdddf;
}
