$color-fff: #fff;
$color-00297A: #00297a;
$color-0090FF: #0090ff;
$color-D7D9DB: #d7d9db;
$color-78797A: #78797a;
$color-56595C: #56595c;
$color-A0A2A3: #a0a2a3;
$color-EBF4FA: #ebf4fa;
$color-292F33: #292f33;
$color-E0B005: #e0b005;
$color-B4B6B8: #b4b6b8;

$border-radius: 4px;
$rbc-resource-width: 176px;

.salon__appointment {
  margin-top: -9px;
  margin-bottom: -24px;
  &-modal {
    &-create {
      &-select-services {
        .ant-modal-body {
          padding-top: 0 !important;
        }
      }
      &-block-time {
        .ant-modal-close {
          right: 16px;
        }
      }
      &-turn {
        .ant-modal-close {
          right: 16px;
        }
      }
      &-customer {
        .ant-modal-body {
          padding-bottom: 26px;
        }
        .ant-modal-close {
          right: 24px;
        }
      }
      &-deposit {
        .ant-modal-close {
          right: 10px;
        }
        &__card-payment {
          .ant-modal-close {
            right: 20px;
          }
          .ant-modal-header,
          .ant-modal-body,
          .ant-modal-footer {
            padding: 20px !important;
          }
        }
      }
      &-calendar {
        .ant-modal-body {
          padding-top: 14px !important;
          height: 700px !important;
          max-height: 700px !important;
          .salon__appointment-calendar .rbc-calendar {
            height: 550px !important;
          }
        }
        &-technician {
          .ant-modal-body {
            padding-left: 10px !important;
            padding-right: 10px !important;
          }
        }
      }
    }
  }
  & .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 20ch;
  }

  &-sidebar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 11px;

    &-left {
      &-heading {
        font-size: 24px;
        color: $color-56595C;
        margin-bottom: 0;
      }
    }

    &-button-text {
      color: $color-00297A;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 16px;

      &-button {
        display: flex;
        height: 10px;
      }

      & .ant-btn {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          border: 0;
          padding: 0;
          background: transparent;
          box-shadow: none;
          outline: none;

          &:focus-visible {
            outline: none;
            outline-offset: 0;
            transition: none;
          }

          &:hover,
          &:active {
            color: $color-00297A;
            border-color: transparent;
          }
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
          display: flex;
          align-items: center;

          & > span {
            display: flex;
            align-items: center;
            gap: 4px;

            &:nth-child(2) {
              font-weight: 700;
              font-size: 14px;
              color: $color-00297A;
            }
          }
        }

        &:nth-of-type(2) {
          & > span {
            font-size: 16px;
          }
        }

        &:nth-last-child(1) {
          // padding: 0 16px 2px;
          height: 32px;
          border-radius: $border-radius;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 32px;
          width: 98px;
          box-shadow: none;

          & > span {
            color: $color-fff;
            font-size: 16px;
            display: inline-block;

            &:nth-child(2) {
              margin-left: 3px;
            }
          }
        }
      }
    }
  }

  &-container {
    background: transparent;

    //custom react-big-calendar
    & .rbc-calendar {
      height: calc(100vh - 174px);

      &:hover {
        & .rbc-time-content {
          overflow: auto !important;
        }
      }

      & .rbc-time-view {
        border: 0;
        $distance-with-scrollbar: 20px;

        & .rbc-time-header {
          gap: 5px;
          margin-bottom: 26px;
          padding-right: $distance-with-scrollbar;

          &.rbc-overflowing {
            border: 0;
          }

          &-content {
            min-width: $rbc-resource-width;
            border: 0;

            & .rbc-row.rbc-row-resource {
              border: 0;
            }

            & .rbc-header {
              border: 0;
              padding: 0;
            }
          }

          & .rbc-allday-cell {
            display: none;
          }

          & .rbc-label {
            border: 0;
            background: transparent;
          }
        }

        & .rbc-time-content {
          border: 0;
          padding-right: $distance-with-scrollbar;
          overflow: hidden;
          padding-top: 10px;

          &.more-width {
            &::-webkit-scrollbar {
              width: 10px;
            }
          }

          &.more-height {
            &::-webkit-scrollbar {
              height: 10px;
            }
          }

          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #dbdddf;
            border-radius: 8px;
            margin-left: 50px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-00297A;
            border-radius: 8px;
          }

          & .rbc-time-gutter {
            border: 0;
            background-color: transparent;

            & > .rbc-timeslot-group {
              border: 0;
              min-height: 128px;

              & .rbc-label {
                font-size: 16px;
                color: $color-56595C;
                font-weight: 500;
                line-height: 140%;
                position: relative;
                top: -12px;
              }
            }
          }

          & .rbc-day-slot {
            background: transparent;
            min-width: 180px; //176px + gap: 4px = 180px

            &:nth-child(2) {
              & > .rbc-current-time-indicator {
                &::before {
                  content: '';
                  background: url('../../../assets/images/appointment/clock.png');
                  display: block;
                  width: 16px;
                  height: 16px;
                  z-index: 3;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -20px;
                }
              }
            }

            & > .rbc-current-time-indicator {
              background-color: $color-0090FF;
            }

            & .rbc-events-container {
              margin-left: 4px;
              margin-right: 0;
            }

            & .rbc-event {
              box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
              min-height: 32px;
              max-height: 100%;
              padding: 8px 11px;
              z-index: 4;
            }

            & > .rbc-timeslot-group {
              min-height: 128px;

              & > .rbc-time-slot {
                border-top: 1px dashed $color-B4B6B8;

                &:first-child {
                  border-top-color: $color-78797A;
                }

                &-blocktime {
                  background-color: #838383;
                  border-top-color: transparent;

                  &:first-child {
                    border-top-color: transparent;
                  }
                }
              }
            }
          }

          & > * + * > * {
            border: 0;
          }
        }
      }

      &-custom {
        &-indicator {
          & > .rbc-time-view {
            & > .rbc-time-content {
              & > .rbc-time-gutter {
                & > .rbc-timeslot-group {
                  & .rbc-time-slot-custom {
                    & .rbc-label {
                      color: $color-0090FF;
                    }
                  }
                }
              }

              & .rbc-day-slot {
                &:nth-child(2) {
                  & > .rbc-current-time-indicator {
                    &::before {
                      content: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &-filter {
    &-days {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      border-bottom: 4px solid $color-00297A;
      border-radius: 2px 0px 0px 2px;
      margin-bottom: 10px;

      & .salon__form-date-picker {
        margin-bottom: 0;
      }

      & &-picker {
        border: 0 !important;
        outline: 0;
        box-shadow: none;
        background: transparent;
        height: auto !important;
        cursor: pointer;
        width: auto !important;
        padding: 0 0 8px 4px !important;

        & input {
          display: none;
        }

        & .ant-picker-suffix {
          margin-inline-start: 0;
        }
      }

      & .salon__form-date-picker-custom {
        width: auto !important;
        margin-bottom: 4px;
        padding-right: 14px;
        cursor: pointer;
      }

      & > .ant-btn {
        margin-bottom: 8px;

        &:nth-of-type(1) {
          margin-right: 10px;
        }

        &:nth-of-type(2) {
          margin-left: 10px;
        }
      }

      &-list {
        display: flex;
        gap: 12px;
        width: calc(100% - 68px);
        justify-content: space-between;
        align-items: flex-end;
        overflow: hidden;
      }

      &-wrapper {
        max-width: 40px;

        &-border {
          border-left: 1px solid $color-D7D9DB;
          padding-left: 7px;
        }

        &-month {
          color: $color-A0A2A3;
          font-size: 18px;
          margin-bottom: 0;
          position: relative;
          background-color: #f4faff;
        }
      }

      &-item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 44px;
        padding: 1px 9px;
        margin-top: 21px;

        &-today {
          margin-bottom: -21px;
          font-size: 14px;
          color: $color-00297A;
        }

        &:hover {
          background: $color-EBF4FA;
        }

        &.selected {
          background: $color-00297A;
          border-radius: $border-radius $border-radius 0 0;
          border-bottom: transparent;

          & > .salon__appointment-filter-days-item-text {
            color: $color-fff;
          }
        }

        & > .weekend {
          color: #ee7e68;
        }

        &-text {
          font-size: 14px;
          color: $color-A0A2A3;
          margin-bottom: 0;
        }
      }
    }

    &-technician {
      &-item {
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 8px 12px;
        background: $color-00297A;
        box-shadow: -2px 4px 20px 0px rgba(0, 0, 0, 0.25);
        border-radius: $border-radius;
        min-width: $rbc-resource-width;

        &:hover {
          & .salon__appointment-filter-technician-item-detail {
            display: none;
          }

          & .salon__appointment-filter-technician-item-display {
            display: flex;
          }
        }

        &-detail {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          justify-content: center;
        }

        &-display {
          display: none;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          height: 51px;

          &-tasks,
          &-hours {
            color: $color-fff;
            display: flex;
            gap: 4px;
          }
        }

        // &-unasigned {
        //   & > .salon__appointment-filter-technician-infor {
        //     align-items: center;

        //     & > .salon__appointment-filter-technician-name {
        //       max-width: 100%;
        //       text-align: center;
        //     }

        //     & > .salon__appointment-filter-technician-detail {
        //       justify-content: center;
        //     }
        //   }
        // }
      }

      &-avatar {
        min-width: 48px;
        width: 48px;
        height: 48px;
        margin-right: 8px;

        & > .ant-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        & .ant-image-img {
          border-radius: $border-radius;
        }
      }

      &-infor {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        // width: 100%;

        &-unasigned {
          align-items: center;

          & .salon__appointment-filter-technician-detail {
            width: 100%;
            justify-content: center;
          }
        }
      }

      &-name {
        font-size: 16px;
        color: $color-fff;
        margin-bottom: 6px;
        text-align: left;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 102px;
      }

      &-detail {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        gap: 8px;
        // width: calc(100% - 56px);
      }

      &-revenue,
      &-tasks {
        color: $color-fff;
        margin-bottom: 0;
        font-size: 14px;
      }

      &-tasks {
        &-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 2px;
        }
      }
    }
  }

  &-calendar {
    &-filter {
      &-button {
        position: absolute;
        top: 166px;
        padding: 20px 14px;
        background: $color-00297A;
        min-height: 67px;
        max-width: 55px;
        border-radius: $border-radius;
        z-index: 10;

        &-text {
          margin-bottom: 0;
        }
      }

      &-popover {
        border-radius: $border-radius;
        width: 413px;

        &-active {
          background-color: #e7ecf0;
        }

        & .salon__form-search-input {
          width: 413px !important;
        }

        & .ant-popover-inner {
          padding: 0;
          box-shadow: -1px -1px 20px 0px rgba(0, 0, 0, 0.1);
        }

        & .salon__form-search-input {
          border: 1px solid $color-D7D9DB;
          border-radius: $border-radius $border-radius 0 0;
          height: 52px !important;
        }

        & .ant-form-item-control {
          max-width: 100%;

          &-input {
            height: 52px !important;
          }
        }

        & .ant-input-affix-wrapper {
          height: 52px;

          & svg {
            width: 16px;
            height: 16px;
          }
        }

        & .ant-form-item-control-input-content {
          & span {
            background-color: transparent;
            border-color: transparent !important;
          }

          & .ant-input {
            background: transparent;
            border-color: transparent !important;
            height: 52px;
            font-size: 12px !important;

            &::placeholder {
              font-size: 12px !important;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        &-box {
          border: 1px solid $color-D7D9DB;
          padding: 1px 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 52px;
          cursor: pointer;

          &:hover {
            background-color: #e7ecf0;
          }

          &:nth-of-type(2) {
            border-bottom: 0;
            border-top: 0;
          }

          &-text {
            margin-bottom: 0;
          }

          &-subtitle {
            color: $color-B4B6B8;
          }
        }

        &-list {
          overflow-y: auto;
          border: 1px solid $color-D7D9DB;
          border-top: 0;
          max-height: 260px;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #dbdddf;
            border-radius: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-00297A;
            border-radius: 8px;
          }
        }

        &-item {
          display: flex;
          align-items: center;
          gap: 4px;
          background-color: $color-00297A;
          padding: 0 8px;
          width: 50%;
          border-radius: $border-radius;
          height: 32px;

          &-wrapper {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 0 12px;
            height: 52px;

            &:hover {
              background-color: #e7ecf0;
            }
          }

          &-avatar {
            & .ant-image-img {
              width: 24px;
              height: 24px;
              object-fit: cover;
            }
          }

          &-name {
            color: $color-fff;
          }
        }
      }
    }

    &-schedule {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      gap: 4px;
      overflow: hidden;

      &-reverse {
        flex-direction: column-reverse;
        justify-content: flex-end;
      }

      &-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      &-title,
      &-name,
      &-time,
      &-status {
        font-size: 12px;
        color: $color-292F33;
        margin-bottom: 0;
      }

      &-title,
      &-name {
        max-width: 130px;
        min-height: 18px;
      }

      &-star {
        color: $color-E0B005;
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      &-time {
        display: flex;
        gap: 2px;
        flex-wrap: wrap;
      }

      &-status {
        margin-bottom: 0;

        &-wrapper {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 4px;
        }
      }
    }

    &-checkout {
      position: fixed;
      bottom: 0;
      border-radius: 8px 8px 0px 0px;
      background-color: $color-fff;
      border: 1px solid $color-00297A;
      padding: 8px 9px 8px;
      width: 317px;
      z-index: 5;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: start;

      &-virtual {
        position: fixed;
        bottom: 0;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 9px 8px;
        width: 317px;
        z-index: 7;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        background-color: transparent;
        min-height: 145px;
        opacity: 0;
      }

      &-expand {
        min-height: 145px;
      }

      & > .ant-btn {
        border: 0;
        padding: 0;
        background: transparent;
        box-shadow: none;
        outline: none;
        z-index: 2;

        &:focus-visible {
          outline: none;
          outline-offset: 0;
          transition: none;
        }

        &:hover,
        &:active {
          color: $color-00297A;
          border-color: transparent;
        }
      }

      &-btn {
        border-radius: $border-radius !important;
        background: $color-D7D9DB !important;
        border: 1px solid $color-D7D9DB !important;
        padding: 0 16px !important;
        height: 24px !important;

        span {
          color: $color-fff;
          font-weight: 700;
          font-size: 16px;
        }

        &-expand {
          position: absolute;
          top: 6px;
          right: 9px;
        }

        &-active {
          background: $color-00297A !important;
          border-color: $color-00297A !important;
        }
      }

      &-empty {
        color: $color-78797A;
        margin: 21.5px 0 0;
        width: 100%;
        text-align: center;
      }

      &-list {
        display: flex;
        min-width: 317px;
        gap: 4px;
        flex: 1 0 435px;
        margin-top: 20px;
        padding-bottom: 8px;

        &-container {
          width: 297px;
          max-height: 115px;
          overflow-x: hidden;
          overflow-y: hidden;
          display: flex;

          &-scroll {
            overflow-x: auto;
          }

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #dbdddf;
            border-radius: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $color-00297A;
            border-radius: 8px;
          }
        }
      }

      &-item {
        padding: 15px 13px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        width: 141px;
        height: 80px;
        position: relative;

        & .ant-btn {
          position: absolute;
          right: 3px;
          top: 1px;
        }

        &-text {
          margin-bottom: 0;
          max-width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &-caller {
    max-width: 314px;
    max-height: 360px;
    height: 360px;
    padding: 24px 16px;
    border-radius: 16px;
    background: $color-fff;

    &-virtual {
      position: absolute;
      z-index: 10;
      opacity: 0.7;
    }

    & .ant-popover-inner {
      background-color: transparent;
      background-clip: unset;
      border-radius: inherit;
      box-shadow: none;
      padding: 0;

      & > .ant-popover-title {
        margin-bottom: 4px;
        height: 24px;
      }
    }

    &-heading {
      color: $color-56595C;
    }

    &-close {
      position: absolute;
      top: 0;
      right: 0;

      border: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;
      outline: none;

      &:focus-visible {
        outline: none;
        outline-offset: 0;
        transition: none;
      }

      &:hover,
      &:active {
        color: $color-00297A;
        border-color: transparent;
      }
    }

    &-subtitle {
      margin-bottom: 4px;
      color: $color-A0A2A3;
    }

    &-empty {
      color: $color-00297A;
      margin-bottom: 0;
      padding-top: 11px;
      font-style: italic;
      line-height: normal;
    }

    &-list {
      padding: 13px;
      border: 1px solid $color-D7D9DB;
      border-radius: $border-radius;
      overflow-y: auto;
      max-height: 236px;
      height: 236px;

      &-container {
        width: 100%;
        height: 100%;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #dbdddf;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-00297A;
        border-radius: 8px;
      }
    }

    &-item {
      padding: 12px 26px;
      background: $color-EBF4FA;
      border-radius: 8px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 165px;
    }

    &-name,
    &-number {
      color: $color-292F33;
    }

    &-star {
      color: $color-E0B005;
      display: flex;
      align-items: center;

      & > &-icon {
        display: flex;
        align-items: center;
        height: 17px;
      }
    }
  }

  &-setting {
    & .ant-popover-inner {
      padding: 12px 0;
    }

    &-item {
      padding: 8px 20px;
      background-color: transparent;

      &:hover {
        background-color: $color-EBF4FA;

        & > .salon__appointment-setting-item-label {
          color: $color-0090FF;
        }
      }
    }
  }

  &-calendar-modal-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
  }

  &-input-create {
    height: 36px !important;
  }

  &-select-create {
    .ant-select-selector {
      height: 36px !important;
      padding: 5px 12px !important;
      .ant-select-selection-overflow {
        height: 22px !important;
        margin-top: -5px !important;
      }
      .ant-select-selection-search-input {
        height: 22px !important;
      }
      .ant-select-selection-search {
        margin-left: 0 !important;
      }
    }
    .ant-select-arrow,
    .ant-select-clear {
      top: 45%;
    }
  }
}
