.salon__checkout-pending-ticket-modal {
  .ant-modal-footer {
    padding: 20px 40px;
  }
  .ant-modal-content {
    padding: 0;
    .ant-modal-header {
      margin: 0;
      padding: 20px 40px;
    }
    .ant-modal-body {
      padding: 20px 40px 0 40px;
    }
  }

  &-title {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  &-text {
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  &-popup {
    &-title {
      font-family: 'Inter';
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      color: #eb4c3b;
    }
    &-content {
      font-family: 'Inter';
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      color: #78797a;

      &-strong {
        font-size: 16px;
        font-family: 'Inter';

        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        color: #78797a;
      }
    }
  }
  &-card {
    width: 780px;
    height: 266px;
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px;
    border: 1px solid #d7d9db;
    overflow: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #dbdddf;
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #00297a;
      border-radius: 8px;
    }
  }
}
